export const GET_ALL_SONGS = 'REPERTOIRE/GET_ALL_SONGS'
export const GET_ARTIST_SONGS = 'REPERTOIRE/GET_ARTIST_SONGS'
export const GET_SEARCH_SONGS = 'REPERTOIRE/GET_SEARCH_SONGS'
export const GET_REPORTED_SONGS = 'REPERTOIRE/GET_REPORTED_SONGS'

export const GET_ALL_ARTISTS = 'REPERTOIRE/GET_ALL_ARTISTS'
export const GET_SEARCH_ARTISTS = 'REPERTOIRE/GET_SEARCH_ARTISTS'
export const GET_REPORTED_ARTISTS = 'REPERTOIRE/GET_REPORTED_ARTISTS'

export const APPROVE_SONG_REPORT = 'REPERTOIRE/APPROVE_SONG_REPORT'
export const APPROVE_SONG_REPORT_CHANGES = 'REPERTOIRE/APPROVE_SONG_REPORT_CHANGES'
export const DECLINE_SONG_REPORT = 'REPERTOIRE/DECLINE_SONG_REPORT'

export const APPROVE_ARTIST_REPORT = 'REPERTOIRE/APPROVE_ARTIST_REPORT'
export const APPROVE_ARTIST_REPORT_CHANGES = 'REPERTOIRE/APPROVE_ARTIST_REPORT_CHANGES'
export const DECLINE_ARTIST_REPORT = 'REPERTOIRE/DECLINE_ARTIST_REPORT'

export const FETCH_ALL_SONGS_PENDING = 'REPERTOIRE/FETCH_ALL_SONGS_PENDING'
export const FETCH_ALL_SONGS_FULFILLED = 'REPERTOIRE/FETCH_ALL_SONGS_FULFILLED'
export const FETCH_ALL_SONGS_REJECTED = 'REPERTOIRE/FETCH_ALL_SONGS_REJECTED'

export const FETCH_REPORTED_SONGS_PENDING = 'REPERTOIRE/FETCH_REPORTED_SONGS_PENDING'
export const FETCH_REPORTED_SONGS_FULFILLED = 'REPERTOIRE/FETCH_REPORTED_SONGS_FULFILLED'
export const FETCH_REPORTED_SONGS_REJECTED = 'REPERTOIRE/FETCH_REPORTED_SONGS_REJECTED'

export const FETCH_ALL_ARTISTS_PENDING = 'REPERTOIRE/FETCH_ALL_ARTISTS_PENDING'
export const FETCH_ALL_ARTISTS_FULFILLED = 'REPERTOIRE/FETCH_ALL_ARTISTS_FULFILLED'
export const FETCH_ALL_ARTISTS_REJECTED = 'REPERTOIRE/FETCH_ALL_ARTISTS_REJECTED'

export const FETCH_REPORTED_ARTISTS_PENDING = 'REPERTOIRE/FETCH_REPORTED_ARTISTS_PENDING'
export const FETCH_REPORTED_ARTISTS_FULFILLED = 'REPERTOIRE/FETCH_REPORTED_ARTISTS_FULFILLED'
export const FETCH_REPORTED_ARTISTS_REJECTED = 'REPERTOIRE/FETCH_REPORTED_ARTISTS_REJECTED'

export const FETCH_DECLINE_REPORT_PENDING = 'REPERTOIRE/FETCH_DECLINE_REPORT_PENDING'
export const FETCH_DECLINE_REPORT_FULFILLED = 'REPERTOIRE/FETCH_DECLINE_REPORT_FULFILLED'
export const FETCH_DECLINE_REPORT_REJECTED = 'REPERTOIRE/FETCH_DECLINE_REPORT_REJECTED'

export const FETCH_APPROVE_REPORT_PENDING = 'REPERTOIRE/FETCH_APPROVE_REPORT_PENDING'
export const FETCH_APPROVE_REPORT_FULFILLED = 'REPERTOIRE/FETCH_APPROVE_REPORT_FULFILLED'
export const FETCH_APPROVE_REPORT_REJECTED = 'REPERTOIRE/FETCH_APPROVE_REPORT_REJECTED'

export const APPROVE_REPERTOIRE_ARTIST = 'REPERTOIRE/APPROVE_ARTIST'
export const APPROVE_REPERTOIRE_ARTIST_FULFILLED = 'REPERTOIRE/APPROVE_ARTIST_FULFILLED'
export const DELETE_REPERTOIRE_ARTIST = 'REPERTOIRE/DELETE_ARTIST'
export const DELETE_REPERTOIRE_ARTIST_FULFILLED = 'REPERTOIRE/DELETE_ARTIST_FULFILLED'
export const UPDATE_REPERTOIRE_ARTIST = 'REPERTOIRE/UPDATE_ARTIST'
export const UPDATE_REPERTOIRE_ARTIST_FULFILLED = 'REPERTOIRE/UPDATE_ARTIST_FULFILLED'

export const APPROVE_REPERTOIRE_SONG = 'REPERTOIRE/APPROVE_SONG'
export const APPROVE_REPERTOIRE_SONG_FULFILLED = 'REPERTOIRE/APPROVE_SONG_FULFILLED'
export const DELETE_REPERTOIRE_SONG = 'REPERTOIRE/DELETE_SONG'
export const DELETE_REPERTOIRE_SONG_FULFILLED = 'REPERTOIRE/DELETE_SONG_FULFILLED'
export const UPDATE_REPERTOIRE_SONG = 'REPERTOIRE/UPDATE_SONG'
export const UPDATE_REPERTOIRE_SONG_FULFILLED = 'REPERTOIRE/UPDATE_SONG_FULFILLED'

export const MERGE_REPERTOIRE_ARTISTS = 'REPERTOIRE/MERGE_ARTISTS'
export const MERGE_REPERTOIRE_SONGS = 'REPERTOIRE/MERGE_SONGS'
