/* global FormData window */

import * as types from './RepertoireTypes'

/* REPERTOIRE APPROVAL */

export const approveRepertoireArtist = id => ({ type: types.APPROVE_REPERTOIRE_ARTIST, id })
export const approveRepertoireArtistFulfilled = id => ({
  type: types.APPROVE_REPERTOIRE_ARTIST_FULFILLED,
  payload: { id },
})
export const deleteRepertoireArtist = id => ({ type: types.DELETE_REPERTOIRE_ARTIST, id })
export const deleteRepertoireArtistFulfilled = id => ({
  type: types.DELETE_REPERTOIRE_ARTIST_FULFILLED,
  payload: { id },
})
export const updateRepertoireArtist = (id, text) => ({
  type: types.UPDATE_REPERTOIRE_ARTIST,
  id,
  text,
})
export const updateRepertoireArtistFulfilled = (id, text) => ({
  type: types.UPDATE_REPERTOIRE_ARTIST_FULFILLED,
  payload: { id, text },
})

export const approveRepertoireSong = id => ({ type: types.APPROVE_REPERTOIRE_SONG, id })
export const approveRepertoireSongFulfilled = id => ({
  type: types.APPROVE_REPERTOIRE_SONG_FULFILLED,
  payload: { id },
})
export const deleteRepertoireSong = id => ({ type: types.DELETE_REPERTOIRE_SONG, id })
export const deleteRepertoireSongFulfilled = id => ({
  type: types.DELETE_REPERTOIRE_SONG_FULFILLED,
  payload: { id },
})
export const updateRepertoireSong = (id, text, year) => ({
  type: types.UPDATE_REPERTOIRE_SONG,
  id,
  text,
  year,
})
export const updateRepertoireSongFulfilled = (id, text, year) => ({
  type: types.UPDATE_REPERTOIRE_SONG_FULFILLED,
  payload: { id, text, year },
})

export const mergeRepertoireArtists = (keepArtistId, replaceArtistId) => ({
  type: types.MERGE_REPERTOIRE_ARTISTS,
  keepArtistId,
  replaceArtistId,
})
export const mergeRepertoireSongs = (keepSongId, replaceSongId) => ({
  type: types.MERGE_REPERTOIRE_SONGS,
  keepSongId,
  replaceSongId,
})

/* FETCH SONGS */
export const fetchArtistSongs = artistId => ({ type: types.GET_ARTIST_SONGS, artistId })
export const fetchAllSongs = (offset, limit) => ({ type: types.GET_ALL_SONGS, offset, limit })
export const fetchAllSongsPending = offset => ({
  type: types.FETCH_ALL_SONGS_PENDING,
  payload: { offset },
})
export const fetchAllSongsFulfilled = (offset, data) => ({
  type: types.FETCH_ALL_SONGS_FULFILLED,
  payload: { data, offset },
})
export const fetchAllSongsError = error => ({ type: types.FETCH_ALL_SONGS_REJECTED, error })
export const fetchSearchSongs = q => ({ type: types.GET_SEARCH_SONGS, q })

/* REPORTED SONGS */
export const approveReportedSong = id => ({ type: types.APPROVE_SONG_REPORT, id })
export const approveReportedSongWithChanges = (id, text, year) => ({
  type: types.APPROVE_SONG_REPORT_CHANGES,
  id,
  text,
  year,
})
export const declineReportedSong = id => ({ type: types.DECLINE_SONG_REPORT, id })

/* FETCH ARTISTS */
export const fetchAllArtists = (offset, limit) => ({ type: types.GET_ALL_ARTISTS, offset, limit })
export const fetchAllArtistsPending = offset => ({
  type: types.FETCH_ALL_ARTISTS_PENDING,
  payload: { offset },
})
export const fetchAllArtistsFulfilled = (offset, data) => ({
  type: types.FETCH_ALL_ARTISTS_FULFILLED,
  payload: { data, offset },
})
export const fetchAllArtistsError = error => ({ type: types.FETCH_ALL_ARTISTS_REJECTED, error })
export const fetchSearchArtists = q => ({ type: types.GET_SEARCH_ARTISTS, q })

/* REPORTED ARTISTS */
export const approveReportedArtist = id => ({ type: types.APPROVE_ARTIST_REPORT, id })
export const approveReportedArtistWithChanges = (id, text) => ({
  type: types.APPROVE_ARTIST_REPORT_CHANGES,
  id,
  text,
})
export const declineReportedArtist = id => ({ type: types.DECLINE_ARTIST_REPORT, id })

/* FETCH REPORTED ARTISTS */
export const fetchReportedArtists = (offset, limit) => ({
  type: types.GET_REPORTED_ARTISTS,
  offset,
  limit,
})
export const fetchReportedArtistsPending = () => ({ type: types.FETCH_REPORTED_ARTISTS_PENDING })
export const fetchReportedArtistsFulfilled = data => ({
  type: types.FETCH_REPORTED_ARTISTS_FULFILLED,
  payload: { data },
})
export const fetchReportedArtistsError = error => ({
  type: types.FETCH_REPORTED_ARTISTS_REJECTED,
  error,
})

/* FETCH REPORTED SONGS */
export const fetchReportedSongs = (offset, limit) => ({
  type: types.GET_REPORTED_SONGS,
  offset,
  limit,
})
export const fetchReportedSongsPending = () => ({ type: types.FETCH_REPORTED_SONGS_PENDING })
export const fetchReportedSongsFulfilled = data => ({
  type: types.FETCH_REPORTED_SONGS_FULFILLED,
  payload: { data },
})
export const fetchReportedSongsError = error => ({
  type: types.FETCH_REPORTED_SONGS_REJECTED,
  error,
})

/* APPROVE REPORT */
export const fetchApproveReportPending = () => ({ type: types.FETCH_APPROVE_REPORT_PENDING })
export const fetchApproveReportFulfilled = result => ({
  type: types.FETCH_APPROVE_REPORT_FULFILLED,
  payload: { result },
})
export const fetchApproveReportFailed = error => ({
  type: types.FETCH_APPROVE_REPORT_REJECTED,
  error,
})

/* DECLINE REPORT */
export const fetchDeclineReportPending = () => ({ type: types.FETCH_DECLINE_REPORT_PENDING })
export const fetchDeclineReportFulfilled = result => ({
  type: types.FETCH_DECLINE_REPORT_FULFILLED,
  payload: { result },
})
export const fetchDeclineReportFailed = error => ({
  type: types.FETCH_DECLINE_REPORT_REJECTED,
  error,
})

export const mergeArtistsAction = (keepArtistId, replaceArtistId) => ({
  type: types.MERGE_REPERTOIRE_ARTISTS,
  keepArtistId,
  replaceArtistId,
})

export const mergeSongsAction = (keepSongId, replaceSongId) => ({
  type: types.MERGE_REPERTOIRE_SONGS,
  keepSongId,
  replaceSongId,
})
