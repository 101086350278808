import { createSelector } from 'reselect'
import * as types from './RepertoireTypes'

const initialState = {
  songs: {
    data: null,
    isMore: false,
    hasFetched: false,
    isFetching: false,
  },
  reportedSongs: {
    data: null,
    hasFetched: false,
    isFetching: false,
  },
  artists: {
    data: null,
    isMore: false,
    hasFetched: false,
    isFetching: false,
  },
  reportedArtists: {
    data: null,
    hasFetched: false,
    isFetching: false,
  },
}

const repertoireReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DELETE_REPERTOIRE_ARTIST_FULFILLED: {
      const index = state.artists.data.findIndex(artist => artist.id === action.payload.id)
      if (index !== -1) {
        return {
          ...state,
          artists: {
            ...state.artists,
            data: [...state.artists.data.slice(0, index), ...state.artists.data.slice(index + 1)],
          },
        }
      }
      return state
    }
    case types.APPROVE_REPERTOIRE_ARTIST_FULFILLED: {
      const index = state.artists.data.findIndex(artist => artist.id === action.payload.id)
      if (index !== -1) {
        return {
          ...state,
          artists: {
            ...state.artists,
            data: [
              ...state.artists.data.slice(0, index),
              { ...state.artists.data[index], approved: true },
              ...state.artists.data.slice(index + 1),
            ],
          },
        }
      }
      return state
    }
    case types.UPDATE_REPERTOIRE_ARTIST_FULFILLED: {
      const index = state.artists.data.findIndex(artist => artist.id === action.payload.id)
      if (index !== -1) {
        return {
          ...state,
          artists: {
            ...state.artists,
            data: [
              ...state.artists.data.slice(0, index),
              { ...state.artists.data[index], artist: action.payload.text },
              ...state.artists.data.slice(index + 1),
            ],
          },
        }
      }
      return state
    }
    case types.FETCH_ALL_ARTISTS_PENDING: {
      return { ...state, artists: { ...state.artists, isFetching: true } }
    }
    case types.FETCH_ALL_ARTISTS_FULFILLED: {
      let artistList = []
      if (action.payload.offset > 0) {
        artistList = state.artists.data.concat(action.payload.data.results)
      } else {
        artistList = action.payload.data.results
      }

      return {
        ...state,
        artists: {
          data: artistList,
          isMore: action.payload.data.isMore,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case types.FETCH_ALL_ARTISTS_REJECTED: {
      return { ...state, artists: { data: null, hasFetched: true, isFetching: false } }
    }

    case types.FETCH_REPORTED_ARTISTS_PENDING: {
      return { ...state, reportedArtists: { ...state.reportedArtists, isFetching: true } }
    }
    case types.FETCH_REPORTED_ARTISTS_FULFILLED: {
      return {
        ...state,
        reportedArtists: {
          data: action.payload.data,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case types.FETCH_REPORTED_ARTISTS_REJECTED: {
      return { ...state, reportedArtists: { data: null, hasFetched: true, isFetching: false } }
    }

    case types.DELETE_REPERTOIRE_SONG_FULFILLED: {
      const index = state.songs.data.findIndex(song => song.id === action.payload.id)
      if (index !== -1) {
        return {
          ...state,
          songs: {
            ...state.songs,
            data: [...state.songs.data.slice(0, index), ...state.songs.data.slice(index + 1)],
          },
        }
      }
      return state
    }
    case types.APPROVE_REPERTOIRE_SONG_FULFILLED: {
      const index = state.songs.data.findIndex(song => song.id === action.payload.id)
      if (index !== -1) {
        return {
          ...state,
          songs: {
            ...state.songs,
            data: [
              ...state.songs.data.slice(0, index),
              { ...state.songs.data[index], approved: true },
              ...state.songs.data.slice(index + 1),
            ],
          },
        }
      }
      return state
    }
    case types.UPDATE_REPERTOIRE_SONG_FULFILLED: {
      const index = state.songs.data.findIndex(song => song.id === action.payload.id)
      if (index !== -1) {
        return {
          ...state,
          songs: {
            ...state.songs,
            data: [
              ...state.songs.data.slice(0, index),
              { ...state.songs.data[index], song: action.payload.text, year: action.payload.year },
              ...state.songs.data.slice(index + 1),
            ],
          },
        }
      }
      return state
    }
    case types.FETCH_ALL_SONGS_PENDING: {
      return { ...state, songs: { ...state.songs, isFetching: true } }
    }
    case types.FETCH_ALL_SONGS_FULFILLED: {
      let songList = []
      if (action.payload.offset > 0) {
        songList = state.songs.data.concat(action.payload.data.results)
      } else {
        songList = action.payload.data.results
      }
      return {
        ...state,
        songs: {
          data: songList,
          isMore: action.payload.data.isMore,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case types.FETCH_ALL_SONGS_REJECTED: {
      return { ...state, songs: { data: null, hasFetched: true, isFetching: false } }
    }

    case types.FETCH_REPORTED_SONGS_PENDING: {
      return { ...state, reportedSongs: { ...state.reportedSongs, isFetching: true } }
    }
    case types.FETCH_REPORTED_SONGS_FULFILLED: {
      return {
        ...state,
        reportedSongs: {
          data: action.payload.data,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case types.FETCH_REPORTED_SONGS_REJECTED: {
      return { ...state, reportedSongs: { data: null, hasFetched: true, isFetching: false } }
    }

    default: {
      return state
    }
  }
}

const repertoireState = state => state.repertoire

export default repertoireReducer

export const getArtists = createSelector(repertoireState, state => state.artists.data || [])
export const getArtistsIsLoading = createSelector(repertoireState, state => state.artists.isFetching)
export const getArtistsHasLoaded = createSelector(repertoireState, state => state.artists.hasFetched)
export const getArtistsIsMore = createSelector(repertoireState, state => state.artists.isMore)

export const getReportedArtists = createSelector(repertoireState, state => state.reportedArtists.data || [])
export const getReportedArtistsIsLoading = createSelector(repertoireState, state => state.reportedArtists.isFetching)
export const getReportedArtistsHasLoaded = createSelector(repertoireState, state => state.reportedArtists.hasFetched)

export const getSongs = createSelector(repertoireState, state => state.songs.data || [])
export const getSongsIsLoading = createSelector(repertoireState, state => state.songs.isFetching)
export const getSongsHasLoaded = createSelector(repertoireState, state => state.songs.hasFetched)
export const getSongsIsMore = createSelector(repertoireState, state => state.songs.isMore)

export const getReportedSongs = createSelector(repertoireState, state => state.reportedSongs.data || [])
export const getReportedSongsIsLoading = createSelector(repertoireState, state => state.reportedSongs.isFetching)
export const getReportedSongsHasLoaded = createSelector(repertoireState, state => state.reportedSongs.hasFetched)
